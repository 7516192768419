import React, { Component } from "react"
import { Helmet } from "react-helmet";
import Layout from "../components/layout";


import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import "../css/swiper-bundle.min.css";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
// import ReactFullpage from '@fullpage/react-fullpage';

import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Link } from "gatsby";



Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);




class Termsandconditions  extends Component {
    componentDidMount(){
    
   
}


  constructor(props) {
        super(props);
        this.state = {
        }
    }



 

 

  render(){


    
   
    return(
        
      <div>
          <Layout>
          <Helmet>
            <title>
            The WorldGrad
            </title>
            <meta name="description" content=""/>
            <link rel="canonical" href="https://theworldgrad.com/terms-and-conditions/" />
            </Helmet>
            <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active"> Terms and Conditions</li>
										</ol>
				</div>
				</div>
          <section class="countrie-banner-sec-hgttr-privacy">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="countrie-banner-inner-agrs">
                                
                                <div class="text-box-urds wow fadeInUp animated" data-wow-duration="1s" data-wow-delay=".1s">
                                    <h1>The WorldGrad | Terms and Conditions</h1>
                                    <p>Terms and Conditions</p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section class="scrds-dvtr-dnlr-ernd" id="privacy-policy-id-head">

            <div class="container">
             
            <div class="col-md-12 flt-lft">
            <h3>Introduction</h3>
<p>This is a legal and binding agreement between you, the user (referred to as “user” or “you”) of the Programs, as defined below, and Linc Education Services Pte. Ltd. trading as The WorldGrad (referred to as “we”, “us” or “The WorldGrad”) stating the terms that govern your use of the Platform, as defined below. The website <a href="https://www.theworldgrad.com/">www.theworldgrad.com</a> and mobile apps (collectively referred to as the “Platform”) and the information, services and other materials contained therein are provided and operated by The WorldGrad. The WorldGrad offers specially designed online higher education programs (“Programs”).</p>
<p>Please review our Terms of Use, Privacy Policy and other policies available on the Platform (collectively referred to as the “Terms”) that govern the use of the Platform and Programs. By accepting these Terms in any manner or accessing the website, you consent, agree and undertake to abide, be bound by and adhere to the Terms and if you do not agree to these Terms, you are not entitled to avail of/use the Programs and any use thereafter shall be unauthorised.</p>
<p>Each Program may have a separate set of terms dealing with refunds, deferrals, payments, etc. governing such Programs.</p>
<p>These Terms shall apply to The WorldGrad hosted mobile apps, WhatsApp groups, Facebook groups, Instagram pages, Facebook pages, email/SMS/phone communications and other social media forums hosted by The WorldGrad, which shall be deemed to be part of the ‘Platform’ by reference. You acknowledge that certain parts of the Platform, as mentioned above, are provided by third-party service providers, and you agree to abide by their terms and conditions. The WorldGrad shall not be responsible for any disruption of services caused by such third-party service providers.</p>
<p>Although you may "bookmark" a particular portion of the Platform and thereby bypass these Terms, your use of the Platform still binds you to these Terms.</p>
<p>We may change these Terms from time to time without prior notice. You should review this page regularly. Your continued use of the Platform and Programs after changes have been made will be taken to indicate that you have read and accepted those changes. You should not use the Platform or Programs if you are not happy with any changes to these Terms.</p>
<p>The WorldGrad makes no representations that the Platform operates (or is legally permitted to operate) in all geographic areas, or that the Platform, or information, services or products offered through the Platform are appropriate or available for use in other locations. Accessing the Platform from territories where the Platform, or any content or functionality of the Platform or its portions thereof is illegal, is expressly prohibited. If you choose to access the Platform, you agree and acknowledge that you do so on your own initiative and at your own risk and that you are solely responsible for compliance with all applicable laws.</p>

<h3><b>User Conduct Policy</b></h3>
<p>Whether you have enrolled in a Program or are simply browsing the Platform, once you have accessed the Platform or Programs, you shall be considered a ‘user’ for the purpose of these Terms. You are responsible for all your activities in connection with the use of the Platform and Programs. You hereby agree to fully comply with all applicable local, provincial, state, national and foreign laws, treaties and regulations in connection with such use. You shall not resort to any unethical practices while using the Platform.</p>
<p>Without limitation, you will not post or transmit, or cause to be posted or transmitted, any communication or solicitation or other "phishing", "pharming" or "whaling" message designed or intended to obtain password, account, personal information, confidential information or private information from any user of the Platform or any other third party whatsoever. However, if any such event does take place, The WorldGrad shall not be liable for any loss of data or if the user’s device, computer or any other property is compromised in any manner.</p>
<p>You acknowledge and agree that The WorldGrad has the right to report any and all suspicious or illegal activity to the appropriate legal or police authorities without notice to you.</p>
<p>During your use of the Platform and Programs, you will:</p>

<ul class="term-list">
 	<li>not harass other users, students, attendees, support agents or visitors;</li>
 	<li>not create an atmosphere of disharmony, hostility within student groups;</li>
 	<li>not cheat on any homework assignment or exams for the Program;</li>
 	<li>not post online any secured testing materials;</li>
 	<li>not share solutions to homework assignments or exams; and</li>
 	<li>notify the instructors immediately if you become aware of any other user cheating or breaching these Terms and comply with the requirements of the specific applicable code of conduct, if any, relating directly to a Program into which such user is enrolled.</li>
</ul>
<h4><b>User Accounts, User Registration and Delivery of Services</b></h4>
<p>In order to participate in most Platform activities and to apply for a Program, you will need to provide your personal information. Upon payment of the Program fees – in part or whole, depending on the Program, you will be provided with a personal account (“User Account”) by providing an email address and a password that is unique.</p>
<p>Once the User Account is created, you will be considered as a Student and The WorldGrad shall deliver the terms of use of the Program and grant you access to the Program. An invoice may be provided to you by The WorldGrad or its program partners along with the email confirmation or within two (2) months thereafter. This shall be a proof of delivery against the successful payment of fees.</p>
<p>Owing to certain exceptional circumstances, if The WorldGrad has granted you access to a Program pending payment, and The WorldGrad does not receive payments within the stipulated time periods, The WorldGrad may, at its own discretion, withdraw your access to the Program without giving any prior notice.</p>
<p>You agree that you will never divulge or share access to your User Account with any third party for any reason. You also agree that you will create, use and/or access only one User Account, and that you will not use any User Account other than your own.</p>
<p>In setting up your User Account, you may be prompted or required to enter additional information, including but not limited to your name and email address. Additional information may be required to confirm your identity. You understand and agree that all information provided by you is accurate, current and complete and that you will maintain and update your information to keep it accurate, current and complete. You acknowledge that if any information provided by you is untrue, inaccurate, not current or incomplete, we reserve the right to terminate your use of the Platform and your enrolment into a Program, to the extent applicable.</p>
<p>In addition to the registration process, as part of your use of the Platform or participation in the Programs, we may obtain certain information about you and your performance in the Programs. Some of this information may be Personally Identifiable Information. We may use, maintain and store this information to provide certain services to you now and in the future, and may share such information with our Educational Partners or third parties in conjunction with such services or for the purpose of marketing. For example, as further detailed in our Privacy Policy, we may share certain materials or information about you with third parties, including your grades/scores in our classes. Irrespective of the fact if you have registered yourself  under DND or DNC or NCPR service, you still authorize us to give you a call from us or any third party for the above mentioned purposes till 365 days of your registration with us.</p>
<p>Access to the Programs is restricted to attendees or students who have specifically been granted access by The WorldGrad.</p>
<p>By registering your User Account, you agree that:</p>

<ul class="term-list">
 	<li>you are registered for the Program only once and will not set up multiple User Accounts; and</li>
 	<li>you will abide by these Terms and any terms specific to the Program</li>
 	<li>you agree that you are registering with the intent to complete the Program and assignments, if any, that are relevant and applicable to the Platform or Programs into which you have enrolled.</li>
</ul>
<h3><b>Fees and Refund Policy</b></h3>
<p>Unless otherwise expressly agreed in writing by The WorldGrad, full payment for participation in a Program is required before the start of the Program.  You may cancel your enrolment at any time. Each Program has specific refund terms which shall be applicable in case of refund on account of such cancellation requests.</p>

<h4><b>Tuition Fees</b></h4>
<p>Students are charged tuition fees for the modules in which they are enrolled. The latest tuition fees are available on The WorldGrad website, and will be included in the offer letter issued to the student. Tuition Fees may vary each year and students must pay the latest module tuition fee applicable to the study period in which they are enrolled. It is the student’s responsibility to ensure that tuition fees are paid no later than the due date as per the offer letter.</p>
<p>The tuition fee includes a INR 50,000 (plus GST) non-refundable deposit. This deposit will be deducted from any refunds that are made for the student should they choose to withdraw. Please refer to the “Refunds” section for all applicable cases.</p>

<h4><b>Non-Refundable Deposit</b></h4>
<p>Students have the option to pay a non-refundable deposit of INR 50,000 + GST (other transaction charges may apply) prior to paying the full tuition fee to block their spot in the  respective program. This will guarantee a spot for the student in the program and start date as mentioned in the student’s offer letter.</p>

<h4><b>Other Charges and Fees</b></h4>
<p>Students will be charged a Supplementary Exam fee if they are approved for a supplementary final exam under the Special Consideration policy (also refer to Final Examination policy and the Letter of Offer). The fees must be paid before taking the supplementary exam.</p>

<h4><b>Payment Options and Due Dates</b></h4>
<p>It is the student’s responsibility to ensure that an appropriate payment option has been actioned prior to the due date for the study period in which they are enrolled. Payment Options and due dates are indicated for:
<b>Commencing students</b>: In the offer letter received by students commencing study with UniSmarter for the first time;
<b>Continuing students</b>: No later than close of business on the Friday prior to the week in which teaching of the module commences.</p>

<h4><b>Non Payment of Fees</b></h4>
<p>The WorldGrad reserves the right to cancel a student’s enrolment in a module/s during a study period if the student has not paid tuition fees by the due date. Any student who has outstanding fees at the end of a study period will not receive final grades for the modules in which they have been enrolled so will not have the opportunity to progress to further study with any articulation partners of The WorldGrad.</p>

<h4><b>Refunds</b></h4>
<p>A student may cancel their enrollment in a module / modules at any time. Student notifications of an enrollment cancellation or deferral to another study period, must be made in writing to The WorldGrad. The refund applicable will be dependent upon the date the student notification is received by The WorldGrad.</p>

<table>
<tbody>
<tr>
<td>
<strong>Reason for Refund</strong>
</td>
<td>
<strong>Date Refund Request Received by The WorldGrad</strong>
</td>
<td>
<strong>Refund Applicable</strong>
</td>
</tr>
<tr>
<td>
Offer is accepted and then program commencement is deferred until a later period
</td>
<td>
Prior to commencement of the study period initially nominated by the student
</td>
<td>
<ul class="term-list">
 	<li>All tuition fee already paid by the student are transferred to the future study period</li>
 	<li>Student will be responsible to pay any difference in tuition fee if applicable</li>
 	<li>If after deferring commencement a student chooses not to commence, the refund follows the refund policy</li>
</ul>
</td>
</tr>
<tr>
<td>
Student withdrawal from the program
</td>
<td>
Before 5pm on the Friday of the first week of the first study period / term of the program
</td>
<td>
<ul class="term-list">
 	<li>Full refund of tuition fee paid for the module / modules from which the student is withdrawing, less the  non-refundable deposit</li>
</ul>
</td>
</tr>
<tr>
<td></td>
<td>
After 5pm on the Friday of the first week of the first study period / term of the program
</td>
<td>
<ul class="term-list">
 	<li>No refund is applicable unless the student is deferring enrolment in a module, in which case the fee paid for the module are transferred to the future study period</li>
</ul>
</td>
</tr>
<tr>
<td>
The WorldGrad is unable to deliver a module
</td>
<td>
Not Applicable
</td>
<td>
<ul class="term-list">
 	<li>Refund will be made for a 100% of the tuition fee paid by the student</li>
</ul>
</td>
</tr>
<tr>
<td>
The WorldGrad cancels the student’s enrolment for student misconduct OR terminated enrolment for inability to meet academic / engagement conditions
</td>
<td>
Not Applicable
</td>
<td>
<ul class="term-list">
 	<li>No refund of tuition fee</li>
</ul>
</td>
</tr>
</tbody>
</table>
<h4><b>Visa Refusal</b></h4>
<p>The WorldGrad programs are aimed at attracting genuine, academically qualified students who wish to travel overseas solely for the purpose of education. Students are responsible for securing a visa and using genuine means to do so. The WorldGrad is not responsible for visa outcomes and does not offer refunds in case the student’s visa application is denied or if they fail to clear any compliance requirements related to I20, GTE or CAS processes.
</p>


	  <p>All refunds are paid in Indian Rupees. The refund will be paid to the student unless The WorldGrad is given contrary advice in writing by the student.
</p>
<p>Depending on where you transact with us, the type of payment method used and where your payment was issued, your transaction with us may be subject to foreign exchange fees or exchange rates. The WorldGrad does not support all payment methods, currencies or locations for payment. All applicable taxes are calculated based on the billing information you provide to us at the time of enrolment/purchase.</p>
<p>It is the sole responsibility of the user enrolling into a Program to check the accuracy of, and evaluate the suitability and relevance of, the Program elected. The enrolment into a Program is non-transferable.</p>
<p>If you have subscribed for a free trial, the free trial period will last for the period of time specified during your sign-up. Free trials may not be combined with certain other offers. If you have subscribed to a particular paid service or product within the last six (6) months, or if your Payment Method, physical address, contact number or email address has been associated with an account for that paid service, you are not eligible to receive a free trial for that service or product.</p>

<h3><b>Refunds for English Programs</b></h3>
<p>Based on the program you have opted for, you may be eligible for a full refund in case you are not satisfied with the quality of the teaching. In all instances, a refund will be granted if the student applies for a refund by filing the refund form within 2 hours of completing their first session with their teacher. The refund form is provided to all students who have opted for an English program with a teaching component at the time of onboarding. There are no refunds for content only programs.   </p>

<h3><b>Use of Programs</b></h3>
<p>The WorldGrad reserves the right to cancel or reschedule any Program or live lectures/webinars, or to alter, modify or rearrange the schedule of topics, as well as the point value or weight of assignments, tests, quizzes, exams, projects and other such evaluations of progress. You also understand that The WorldGrad, at its sole discretion, may limit, suspend, or terminate your use of the Platform or Programs and/or all The WorldGrad-provided services related to the Programs, such as access to The WorldGrad coaches or support services, evaluation services, or certifications. You also understand that The WorldGrad may modify or discontinue all services related to its Programs at its sole discretion. You agree that The WorldGrad shall not be liable to you or to any third party for any such modification, suspension or discontinuance. Nothing in these Terms shall be construed to obligate The WorldGrad to maintain and support the Platform or Programs or any part or portion thereof or any associated services.</p>
<p>You agree that The WorldGrad shall be under no liability whatsoever to you in the event of non-availability of the Platform or any portion thereof occasioned by Act of God, war, disease, revolution, epidemic, riot, civil commotion, strike, lockout, flood, fire, satellite failure, failure of any public utility, man-made disaster or any other cause whatsoever beyond the control of The WorldGrad.</p>
<p>If you no longer wish to use any Program, you may terminate your participation by giving a prior notice to The WorldGrad. Participants of The WorldGrad online live lectures and webinars shall be provided access to such classes via email or their Platform. You agree not to share such access with any third party. If you are unable to attend an online live lecture/webinar, please provide The WorldGrad with prior intimation regarding this.</p>
<p>You shall be exclusively responsible for obtaining and maintaining any equipment or ancillary services needed to connect to, access, receive or use the Platform, including, without limitation, hardware devices, software, and other internet, wireless, broadband, phone or other mobile communication device connection services. You shall be exclusively responsible for ensuring that such equipment or ancillary services are compatible with the Platform, and you shall be responsible for all charges incurred in connection with the use of the Platform and Program in connection with all such equipment and ancillary services.</p>

<h3><b>Educational Partners and Accreditation</b></h3>
<p>The WorldGrad is NOT a University, but an education service provider, offering Programs in consultation with International universities, educational institutions, corporates, academic networks, etc. (each an “Educational Partner”).</p>
<p>Admission to the degree/diploma programs of an Educational Partner is separate from being accepted into and/or taking one or more Programs offered by The WorldGrad. You agree and acknowledge that nothing in these Terms or otherwise with respect to the use of the Platform or any Program (a) enrolls or registers you in an Educational Partner, or any other school, division, institute or program of or associated with an Educational Partner, and (b) allows you to access or use the resources or receive any benefits or privileges of any Educational Partner, beyond the Programs.</p>
<p>The WorldGrad may offer a credential or other acknowledgement for participants who have satisfactorily demonstrated mastery of the Program material. The decision to award any such credential acknowledgement to a given participant will be solely at the discretion of The WorldGrad. The WorldGrad may choose not to offer any credential or other acknowledgement for some Programs. The WorldGrad may decide at its sole discretion whether to provide a record concerning a participant’s performance in a Program. The format of any credential or other acknowledgement, and of any performance, provided by The WorldGrad relating to Programs will be determined by The WorldGrad at its sole discretion and may vary from Program to Program.</p>
<p>You will be awarded a certificate of successful completion of a Program (“Program Certificate”) only upon fulfilling the criteria and requirements of The WorldGrad and its Educational Partners. You acknowledge that any such Program Certificate awarded may not be affiliated to any Educational Partner and may not stand in the place of a course taken with an Educational Partner or convey academic credit or certification for any Educational Partner.</p>
<p>You will not receive academic credit from The WorldGrad or any other Educational Partner for taking a Program. If you are taking a Program for academic credit or certification as a student of, or otherwise through, an Educational Partner, any such credit or certification may only be awarded directly by the Educational Partner based on its own policies and procedures, and you may be required by that Educational Partner to be registered or enrolled with the Educational Partner in order to receive credit or certification; and, in any event, The WorldGrad will not have any authority or responsibility with respect to any award of academic credit or certification provided by an Educational Partner for a Program.</p>
<p>You acknowledge and agree that any Program affiliated with an Educational Partner may be subject to the terms, policies and procedures of the applicable Educational Partner in addition to The WorldGrad’s Terms. Without limiting the foregoing, if you are a student registered or enrolled at, or are otherwise attending, an Educational Partner and are taking a Program for credit or certification through that Educational Partner, you acknowledge and agree that (a) the Educational Partner may have its own terms, policies or procedures regarding your eligibility to participate in the Program, your participation in the Program, the requirements or prerequisites for receiving credit or certification for the Program, and/or your educational or student records as they may relate to your participation and performance in the Program, and (b) your educational or student records are maintained by the Educational Partner, including for purposes of assigning credit or certification, and not The WorldGrad.</p>

<h3><b>The WorldGrad’s Intellectual Property Rights</b></h3>
<p>“Content” means any and all information and data, which may include but not be limited to text, software, scripts, graphics, maps, photos, sounds, music, videos, logos, offers, advertisements, interactive features and other materials.</p>
<p>The Platform, Programs and related services are owned and operated by The WorldGrad and its licensors. All Content or other material available on the Platform or through the Programs, including but not limited to online/live lectures, speeches, video lessons, quizzes, presentation materials, homework assignments, programming assignments, programs, code, and other images, text, layouts, arrangements, displays, illustrations, documents, materials, audio and video clips, HTML and files (collectively “The WorldGrad Content”), are the property of The WorldGrad and/or its affiliates or licensors or Educational Partners, and are protected by copyright, patent and/or other proprietary intellectual property rights under Indian and foreign laws. All software used on the Platform is the property of The WorldGrad and is protected by Indian and international copyright laws.</p>
<p>The WorldGrad logos, trademarks and service marks that may appear on the Platform and in the Program (“The WorldGrad Marks”) are the property of The WorldGrad and are protected under Indian and foreign laws. All other trademarks, service marks and logos used on the Platforms, Online Courses or Programs, with or without attribution, are the trademarks, service marks or logos of their respective owners. In addition, elements of the Platforms are protected by trade dress and other Indian and international intellectual property laws and may not be copied, reproduced, downloaded or distributed in any way in whole or in part without the express written consent of The WorldGrad.</p>
<p>As a condition of accessing the Platforms and/or using the Programs, you agree not to (a) reproduce, duplicate, copy, sell, resell or exploit for any commercial purpose any The WorldGrad Content or any portion of it thereof, other than as expressly allowed under these Terms; and (b) use the The WorldGrad Marks or the name, trademarks, service marks, or other materials of any Educational Partner in connection with, or to transmit, any unsolicited communications or emails or for any other unauthorised purpose.</p>
<p>Please verify all Content prior to use. In the event you come across any Content that is incorrect, infringing, offensive, indecent or objectionable, please notify us immediately at the address mentioned at the bottom of this page.</p>
<p>Additionally, from time to time, The WorldGrad (or its third-party service providers, on behalf of The WorldGrad) may request users to review The WorldGrad Content or beta-test the Platform. The works derived from such activity shall remain the sole and exclusive property of The WorldGrad or its third-party service providers, as applicable.</p>

<h4><b>Third-Party Intellectual Property</b></h4>
<p>The WorldGrad respects third-party intellectual property rights and actively supports protection of all third-party intellectual property including copyrights and trademarks (“IP”). It is our policy to expeditiously respond to clear notices of alleged IP infringement. If we receive proper notification of IP infringement, our response to such notices will include removing or disabling access to material claimed to be the subject of infringing activity.</p>
<p>The WorldGrad shall not be held liable for the unauthorised use of any third-party IP, and the user that carries out such unauthorised use or infringes any IP available on the Platform shall fully indemnify and hold The WorldGrad harmless against any and all claims that may arise as a result of such use.</p>
<p>If you believe that your product or other work has been misrepresented or used in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide us with the following information:</p>

<ul class="term-list">
 	<li>an electronic or physical signature of the person authorised to act on behalf of the owner of the product, copyright or other intellectual property interest;</li>
 	<li>a description of the product, work or other intellectual property that you claim has been misrepresented or infringed;</li>
 	<li>a description of where the material that you claim is misrepresenting or infringing your product, work or other intellectual property is located on the Platform/Program;</li>
 	<li>your address, telephone number, and email address;</li>
 	<li>a statement by you that you have a good faith belief that the disputed use is not authorised by the owner of the product, work, copyright or intellectual property, or its agent, or applicable law; and</li>
 	<li>a statement by you, made under the penalty of perjury, that the aforementioned information in your notice is accurate and that you are the product, work, copyright or intellectual property owner or authorised to act on such owner's behalf.</li>
</ul>
<p>For notices of disputes or claims of copyright or other intellectual property infringement, please connect with us at the address provided at the bottom of this page.</p>

<h4><b>Limited License</b></h4>
<p>The services on the Platform are licensed, not sold. In consideration for your agreement to these Terms, The WorldGrad grants you a personal, non-exclusive, non-transferable, revocable license to access and use the Platform and Programs, solely in accordance with the Terms. You may download or copy the portions of The WorldGrad Content available on the Platform for your own non-commercial and personal use only, provided you maintain all copyright and other notices contained in such The WorldGrad Content. You may not copy, sell, resell, reproduce, publish, modify, transfer, retransmit, distribute, commercially exploit or create derivative works of Platforms, Programs or any The WorldGrad Content. Notwithstanding the foregoing, certain reference documents, digital textbooks and articles may be made available to you with the permission of third parties, and use of that information is subject to certain rules and conditions, and you agree to abide by all such rules and conditions.</p>
<p>You may not reverse-engineer, decompile, disassemble or otherwise access the source code for any software that may be used to operate the Platform or the Programs. From time to time, The WorldGrad may include software, code, instructions, or other such information in the The WorldGrad Content for the Programs; any such information is provided on an "as-is" basis for instructional purposes only and is subject to the ‘Disclaimer’ and ‘Limitation of Liability’ sections below and other terms herein. Any use of such information for commercial purposes is strictly prohibited. The WorldGrad and/or its affiliates and licensors reserve all rights not expressly granted herein to the Platforms, The WorldGrad Content, and The WorldGrad Marks.</p>
<p>Without limiting the generality of the terms above, the following are types of uses that The WorldGrad expressly defines as falling outside of the definition of "non-commercial and personal use":</p>

<ul class="term-list">
 	<li>the sale or rental of (i) any part of the The WorldGrad Content, (ii) any derivative works based at least in part on the The WorldGrad Content, or (iii) any collective work that includes any part of the The WorldGrad Content;</li>
 	<li>the sale of access or a link to any part of the The WorldGrad Content;</li>
 	<li>providing training, support, or editorial services that use or reference the The WorldGrad Content in exchange for a fee;</li>
 	<li>the use of The WorldGrad Content by a college, university, school, or other educational institution for instruction where tuition is charged;</li>
 	<li>the use of The WorldGrad Content by a for-profit corporation or non-profit entity for internal professional development or training; and</li>
 	<li>the use of The WorldGrad Content for display / upload / making available at a place that is accessible to the general public in any manner, including but not limited to any social media sites, video download sites, and torrents.</li>
</ul>
<h4><b>User Content</b></h4>
<p>The Platform may allow you to upload forum posts, chat with other users and The WorldGrad’s counsellors, user discussions, profile pages, and other content and media for social interaction, or certain information and materials for use with the Programs, e.g., questions, hypotheticals, examples, assignments, industry projects, etc. (collectively "User Content"). The WorldGrad does not claim ownership of any User Content you may submit or make available for inclusion on the Platform or Programs. Accordingly, subject to the license granted to The WorldGrad above, the user will be the sole and exclusive owner of any and all rights, title and interest in and to the User Content.</p>
<p>With respect to any User Content you submit via the Platform or Programs or that is otherwise made available to The WorldGrad, you hereby grant The WorldGrad an irrevocable, worldwide, perpetual, royalty-free and non-exclusive license to use, distribute, reproduce, modify, adapt, publicly perform and publicly display such User Content on the Platforms or in the Programs or otherwise exploit the User Content, with the right to sublicense such rights (to multiple tiers), for any purpose (including for any commercial purpose). The WorldGrad reserves the right to remove any User Content at any time and for any reason.</p>
<p>To the extent that you provide any User Content, you represent and warrant that (a) you have all necessary rights, licenses and/or clearances to provide such User Content and permit The WorldGrad to use such User Content as provided above, (b) such User Content is accurate and reasonably complete, (c) as between you and The WorldGrad, you shall be responsible for the payment of any third-party fees related to the provision and use of such User Content, and (d) such User Content does not and will not infringe or misappropriate any third-party rights or constitute a fraudulent statement or misrepresentation or unfair business practices.</p>
<p>The WorldGrad does not control the User Content posted through the Platform, including any messages, reviews or comments, and does not guarantee the accuracy, integrity or quality of such User Content. Under no circumstances will The WorldGrad be liable in any way for any User Content, including any errors or omissions, or any loss or damage or defamation of any kind incurred as a result of your posting or use of any User Content. You are responsible for complying with all the laws applicable to the User Content.</p>
<p>You are prohibited from posting the following Content on the Platform:</p>

<ul class="term-list">
 	<li>Content that is fraudulent, indecent, or libellous, or defames, harasses, discriminates against, harms or threatens others;</li>
 	<li>Content that discusses illegal activities, with the intent to commit them;</li>
 	<li>Content that infringes or misappropriates patents, trademarks, trade secrets, right of publicity, or other intellectual property rights of third parties;</li>
 	<li>Content that you do not have the right to disclose;</li>
 	<li>Profane, pornographic, obscene, indecent or unlawful Content;</li>
 	<li>Advertising or any form of commercial solicitation;</li>
 	<li>Content related to partisan political activities;</li>
 	<li>Content that contains intentionally inaccurate information or that is posted with the intent of misleading others;</li>
 	<li>Viruses, Trojan horses, worms, time bombs, cancelbots, or other disabling devices or other harmful components intended to or that may damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information;</li>
 	<li>Content that consists of any high volume, automated, or electronic means to access the Platform (including without limitation robots, spiders or scripts);</li>
 	<li>Content that violates the rights of other users of the Platform; or</li>
 	<li>Content that violates any applicable local, state, national or international law or otherwise advocates or encourages any illegal activity.</li>
</ul>
<h3><b>Platform Security</b></h3>
<p>You are prohibited from violating or attempting to violate the security of the Platform or any other associate Platform of The WorldGrad, including, without limitation, (a) accessing data not intended for such user or logging onto a server or an account that the user is not authorised to access; (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorisation; (c) attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus to the Platform, overloading, "flooding," "spamming," "mailbombing" or "crashing;" (d) sending unsolicited emails, including promotions and/or advertising of products or services; or (e) forging any TCP/IP packet header or any part of the header information in any email or newsgroup posting. Violations of system or network security may result in civil or criminal liability. The WorldGrad will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Platform or any activity being conducted on the Platform. You agree, further, not to use or attempt to use any engine, software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent agents) to navigate or search the Platform other than the search engine and search agents available from The WorldGrad on the Platform and other than generally available third-party web browsers (e.g., Google Chrome or Microsoft Explorer).</p>

<h4><b>Disclaimer</b></h4>
<p>The Platform and/or Programs may contain typographical errors or inaccuracies and may not be complete or current. The WorldGrad, therefore, reserves the right to correct any errors, inaccuracies or omissions (including after an order has been submitted) and to change or update information at any time without prior notice.</p>
<p>The Platform, Programs, and any information or The WorldGrad Content are provided on an “as is” and “as available” basis with all faults.</p>
<p>The WorldGrad makes no representations or warranties of any kind, whether expressed or implied, with respect to The WorldGrad Content or services available on or through this Platform and Programs, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. The WorldGrad makes no warranty that the services will meet the user's requirements or that the services will be uninterrupted, timely, secure, or error free; nor does The WorldGrad make any warranty as to the results that may be obtained from the use of the services, or as to the accuracy or reliability of any information obtained through the services, or that any defects in the software will be corrected.</p>
<p>The user understands and agrees that the The WorldGrad Content and all other information, data, or other material downloaded or otherwise obtained through or from the Platform or Programs is obtained at the user's own discretion and risk, and that the user will be solely responsible for any damage to the user, the user's computer system, electronic device or any loss of data that results from the download of such material or data.</p>
<p>No advice or information, whether oral or written, obtained by the user from The WorldGrad or through or from the services, shall create any warranty by The WorldGrad.</p>
<p>In the event you find that the information provided on the Platform or Programs is incorrect, or if you are the owner of any information or content and wish that such information or content is not displayed on the Platform or Programs, kindly notify us at the address provided at the bottom of the page.</p>

<h3><b>Limitation of Liability</b></h3>
<p>Neither The WorldGrad nor any of its Educational Partners, affiliates, employees, directors, officers, agents, vendors or supplier shall be liable to you or any other person, whether in Tort, Contract, Strict Liability or otherwise, for any indirect, special, incidental or consequential losses or damages of any nature arising out of or in connection with the use or inability to use this Platform or Programs, including, without limitation, damages for lost profits, loss of goodwill, loss of data, work stoppage, accuracy of search results, or computer / electronic device failure, virus or malfunction. In no event will The WorldGrad be liable for any damages in excess of ₹1000 or, in the case of enrolled students, the fees paid by you in connection with your enrolment into a Program.</p>

<h4><b>Site Linking</b></h4>
<p>The Platform may contain links to pages on other websites ("Linked Sites"), and those Linked Sites may contain Content or offer products and/or services for sale. The WorldGrad does not author, edit, control, or monitor these Linked Sites. You acknowledge and agree that (a) we have no responsibility for the accuracy or availability of information provided by Linked Sites; (b) we do not control or endorse the sponsors of such Linked Sites or the content, products, advertising, or other materials presented on such Linked Sites; and (c) you agree to the terms and conditions of the Linked Sites. We may remove any Linked Sites from the Platform at any time with or without reason.</p>
<p>The WorldGrad WILL NOT BE LIABLE FOR ANY TRANSACTIONS CONDUCTED BY YOU WITH THIRD PARTIES THROUGH THE LINKED SITES OR FOR ANY LIABILITY ARISING FROM THE REPRESENTATIONS OR INFORMATION PROVIDED ON SUCH LINKED SITES.</p>
<p>We appreciate that Linked Sites may contain material on which the operator of the Linked Sites has intellectual property rights. We respect those rights and provide the links for information purposes only. The fact that we have provided links to any Linked Site does not create or imply any relationship or partnership with the operator of such Linked Site.</p>
<p>You may not establish a link to this Platform from any other website, application, intranet or extranet site without our prior written consent. If you wish to create links, you may contact us before doing so. In establishing links, you must not represent in any way, expressly or by implication, that you have received the endorsement, sponsorship or support of The WorldGrad, including its Educational Partners, respective employees, agents, directors, officers or shareholders.</p>

<h4><b>Indemnity</b></h4>
<p>You agree to indemnify and hold The WorldGrad and its Educational Partners harmless from any and all claims, liabilities, damages, losses and expenses, including reasonable attorneys' fees and costs, relating to or arising out of (a) your use or attempted use of the Platforms or Programs in violation of the Terms; (b) your violation of any law or rights of any third party; or (c) information or Content that you or others post or otherwise make available on the Platform or through any Program, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.</p>

<h3><b>Termination of Rights</b></h3>
<p>You agree that The WorldGrad, in its sole discretion, may deactivate your account or otherwise terminate your use of the Platform or enrolment to a Program with or without reason, including, without limitation, if The WorldGrad believes that you have (a) breached the Terms; (b) infringed the intellectual property rights of a third party; (c) posted, uploaded or transmitted unauthorised Content on the Platform; or (d) violated or acted inconsistently with the letter or spirit of these Terms or any other applicable code of conduct. You agree that any deactivation or termination of your access to the Platforms or Programs may be effected without prior notice to you and that The WorldGrad shall not be liable to you nor any third party for any termination of your account or enrolment into a Program. You also acknowledge that The WorldGrad may retain and store your information on The WorldGrad’s systems notwithstanding any termination of your account or enrolment into the Programs.</p>

<h3><b>Miscellaneous Provisions</b></h3>
<p>By accepting the Terms through your use of the Platform, you certify that you are 18 years of age or older. If you are under the age of 18 or under the legal age in your jurisdiction to enter into a binding contract, you may use the Platform only under the supervision of a parent or legal guardian who agrees to be bound by the Terms. If you are a parent or legal guardian agreeing to the Terms for the benefit of a child below 18, be advised that you are fully responsible for his/her use of the Platform, including all financial charges and legal liability that he/she may incur. By using the Platform, you represent and warrant that you have the right, authority, and capacity to enter into the Terms and to abide by all of the terms and conditions set forth herein. The Programs are not for the use of individuals under the age of 18 years.</p>
<p>The WorldGrad may freely transfer or assign any portion of its rights or delegate its obligations under these Terms or any Program specific terms. You shall not transfer or assign, by operation of law or otherwise, any portion of your rights or delegate your obligations under these Terms or any Program-specific terms without the prior written consent of The WorldGrad.</p>
<p>These Terms or any Program-specific terms shared with you shall be governed by, construed and enforced in accordance with the laws in India, as these are applied to agreements entered into and to be performed entirely within India and without giving effect to any principles of conflict of laws. You agree that any legal lawsuit or other action brought by The WorldGrad, you or any third party to enforce these Terms or any Program-specific terms shared with you, or in connection with any matters related to the Platform or Programs, shall be subject only to the jurisdiction of the courts of Mumbai. Where a dispute arises, the parties involved shall make all reasonable efforts to resolve the dispute through good faith negotiations. If efforts to amicably resolve any dispute or claim between the parties are unsuccessful, then such dispute or claim arising out of or in connection with the Terms or any Program-specific terms shared with you, including any question regarding its existence, validity or termination, shall be referred to arbitration under the Arbitration and Conciliation Act, 1956, as amended (“Act”) before a sole arbitrator to be appointed by The WorldGrad. The proceedings shall be conducted in English and the seat for arbitration shall be Mumbai.</p>
<p>If any provision of the Terms or any Program-specific terms shared with you is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from the other provisions herein, and shall not affect the validity and enforceability of any remaining provisions. The WorldGrad shall have no liability under these Terms or any Program-specific terms shared with you, to the extent arising from any failure of The WorldGrad to perform any of its obligations under these Terms or any Program-specific terms shared with you, due to any fire, flood, earthquakes, other acts of God, war, civil unrest, terrorism, Internet failures, governmental act or court order, national emergency, strikes or labour disputes or any other event not within The WorldGrad's reasonable control. The WorldGrad shall not be responsible for damage or other problems caused by any unauthorised change to these Terms made by way of hacking or cracking this page.</p>
<p>The failure of The WorldGrad to exercise or enforce any right or provision of the Terms or any Program-specific terms shared with you, shall not constitute a waiver of such right or provision. If any provision of the Terms or any Program-specific terms shared with you is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties' intentions as reflected in the provision, to the extent that may lawfully be done, and the other provisions of these Terms or any Program-specific terms shared with you, shall remain in full force and effect.</p>
<p>These Terms or any Program-specific terms shared with you constitute the entire agreement between you and The WorldGrad relating to the matters set forth herein, and shall not be modified except in writing, as posted on the Platforms by The WorldGrad or through a specific writing between you and The WorldGrad.</p>
<p>The WorldGrad may freely transfer or assign any portion of its rights or delegate its obligations under the Terms or any Program-specific terms shared with you. You may not transfer or assign, by operation of law or otherwise, any portion of your rights or delegate your obligations under the Terms or any Program-specific terms shared with you, without the prior written consent of The WorldGrad, and any such attempted transfer or assignment shall be void and of no effect.</p>
<p>If you have any concerns or queries, please reach out to our Grievance Officer. Our Grievance Officer shall undertake all reasonable efforts to address your grievances at the earliest possible opportunity. Any notice or communication that may be required to be given to The WorldGrad under these Terms or any Program-specific terms shared with you may be sent by emailing to the following addresses:</p>
<p>You may contact us at:</p>
                <p><b>Grievance Officer:</b> Pranav Saxena</p>
                <p><b>Email: </b><a href="mailto:grievances@theworldgrad.com">grievances@theworldgrad.com</a></p>
				<p><b>Last Updated: </b> 28th March, 2024</p>


            </div>

            </div>
            
            </section>


            </Layout>
            </div>




            
            
    )

    
  }
}



export default Termsandconditions;
